import produce from 'immer';
import {
    GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE,
    GET_DETAIL, GET_DETAIL_SUCCESS, GET_DETAIL_FAILURE,
    GET_SPECIALTIES, GET_SPECIALTIES_SUCCESS, GET_SPECIALTIES_FAILURE,
    GET_TYPES, GET_TYPES_SUCCESS, GET_TYPES_FAILURE
} from './type';

const initialState = {
    list: [],
    detail: {},
    types: [],
    specialties: [],
    order:{},
    loading: false,
    error: false
}

function hospitalReducer(state = initialState, action) {

    const { payload } = action;
    return produce(state, draft => {
        switch (action.type) {
            // List
            case GET_LIST:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_LIST_SUCCESS:
                draft.list = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_LIST_FAILURE:
                draft.loading = false;
                draft.list = [];
                draft.error = payload;
                break;
            case GET_DETAIL:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_DETAIL_SUCCESS:
                draft.detail = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_DETAIL_FAILURE:
                draft.detail = {};
                draft.loading = false;
                draft.error = payload;
                break;
            case GET_SPECIALTIES:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_SPECIALTIES_SUCCESS:
                draft.specialties = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_SPECIALTIES_FAILURE:
                draft.specialties = [];
                draft.loading = false;
                draft.error = payload;
                break;
            case GET_TYPES:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_TYPES_SUCCESS:
                draft.types = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_TYPES_FAILURE:
                draft.types = [];
                draft.loading = false;
                draft.error = payload;
                break;            
            default:
                break;
        }
    })

}

export default hospitalReducer;