import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

// import css from library
import "lightgallery.js/dist/css/lightgallery.css"; // css lightgallery
import 'react-phone-input-2/lib/style.css';

// multi languages
import './helper/languages';

import './index.css'; // css of app
import App from './App';
import store from "./store";
import reportWebVitals from './reportWebVitals';
import ScrollToTop from 'react-router-scroll-top';
import { HelmetProvider } from 'react-helmet-async';

const jsx = (
	<ReduxProvider store={store}>
		<Router>
			<HelmetProvider>
				<ScrollToTop>
					<App />
				</ScrollToTop>
			</HelmetProvider>
		</Router>
	</ReduxProvider>
);
const app = document.getElementById("app");

if (process.env.NODE_ENV === "development") {
	ReactDOM.render(jsx, app);
	reportWebVitals();
}
else {
	disableReactDevTools();
	ReactDOM.hydrate(jsx, app);
}
