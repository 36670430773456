import { combineReducers } from "redux";

// Destination
import destinationReducer from './destination/reducer';
//Location
import locationReducer from "./location/reducer";
// Product
import productReducer from "./product/reducer";
//hospital
import hospitalReducer from "./hospital/reducer";
// doctor
import doctorReducer from './doctor/reducer';
// service
import serviceReducer from './service-medical/reducer';
// Handbook
import handbookReducer from './handbook/reducer';


// App reducer
const appReducer = combineReducers({
    destinationReducer, productReducer, locationReducer, hospitalReducer, doctorReducer, serviceReducer,
    handbookReducer
});

// Root reducer
const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;
