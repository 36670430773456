import produce from 'immer';
import {
    GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE,
    GET_DETAIL, GET_DETAIL_SUCCESS, GET_DETAIL_FAILURE,
    GET_HANDBOOK_CATEGORY, GET_HANDBOOK_CATEGORY_SUCCESS, GET_HANDBOOK_CATEGORY_FAILURE
} from './type';

const initialState = {
    list: [],
    detail: {},
    types: [],
    categories: [],
    loadingCategory: false,
    loading: false,
    error: false
}

function handbookReducer(state = initialState, action) {

    const { payload } = action;
    return produce(state, draft => {
        switch (action.type) {
            // List
            case GET_LIST:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_LIST_SUCCESS:
                draft.list = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_LIST_FAILURE:
                draft.loading = false;
                draft.list = [];
                draft.error = payload;
                break;
            // Get detail
            case GET_DETAIL:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_DETAIL_SUCCESS:
                draft.detail = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_DETAIL_FAILURE:
                draft.detail = {};
                draft.loading = false;
                draft.error = payload;
                break;
            // Get category
            case GET_HANDBOOK_CATEGORY:
                draft.loadingCategory = true;
                draft.error = false;
                break;
            case GET_HANDBOOK_CATEGORY_SUCCESS:
                draft.categories = payload;
                draft.loadingCategory = false;
                draft.error = false;
                break;
            case GET_HANDBOOK_CATEGORY_FAILURE:
                draft.categories = [];
                draft.loadingCategory = false;
                draft.error = payload;
                break;
            default:
                break;
        }
    })

}

export default handbookReducer;